<template>
    <v-dialog v-model="show" max-width="800px" persistent transition="dialog-bottom-transition" overlay-opacity="0.9">
        <v-card class="dialog-top-color">
            <v-card-title>
                <v-row>
                    <v-col :align="'center'" :justify="'center'">
                        <h2>{{ $t('plan.proPlanDialogTitle') }}</h2>
                    </v-col>
                </v-row>

            </v-card-title>
            <v-card-text>
                <v-row class="mb-3" no-gutters>
                    <v-col>
                        <v-img :src="require('../../assets/plan/PAYWALL.svg')" max-width="800px" max-height="350px" cover>
                        </v-img>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :align="'center'" :justify="'center'">
                        <p v-html="text"></p>
                    </v-col>
                </v-row>
                <template v-if="$vuetify.breakpoint.xs">
                    <v-row no-gutters class="mt-1">
                        <v-col :align="'center'" :justify="'center'">
                            <router-button :text="$t('plan.proPlanDialogButton')" :redirect="`/pricing-plans`">
                            </router-button>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col :align="'center'" :justify="'center'">
                            <v-btn text @click="fromButton ? show = false : $router.go(-1)">
                                {{ $t('label.goBack') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
            <v-card-actions v-if="$vuetify.breakpoint.smAndUp">

                <v-btn text @click="fromButton ? closeDialog() : $router.go(-1)" class="ma-3">
                    {{ $t('label.goBack') }}
                </v-btn>
                <v-spacer />
                <router-button class="ma-3" :text="$t('plan.proPlanDialogButton')" :redirect="`/pricing-plans`">
                </router-button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import RouterButton from "../button/RouterButton.vue"

export default {
    components: { RouterButton },
    name: 'pro-feature-dialog',
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false
        },
        text: {
            type: String,
            required: true
        },
        fromButton: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {

        }
    },
    computed: {

    },
    methods: {
        closeDialog(){

            this.$emit("closeDialog", true)
        }
    }
}
</script>

<style></style>