<template>
  <v-container fluid>
    <v-row no-gutters v-if="$vuetify.breakpoint.smAndUp">
      <v-col cols="auto">
        <v-row no-gutters>
          <h1 class="mr-5">{{ $t('tax.taxCenterTitle') }}</h1>
          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
            min-width="auto" v-if="getAvailableTaxYears.length > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-row :align="'center'">
                <h1>{{ taxYear }}</h1><v-btn class="ml-3" v-bind="attrs" v-on="on" x-small fab
                  color="accent"><v-icon>mdi-chevron-down</v-icon></v-btn>
              </v-row>
            </template>
            <v-date-picker v-model="picker" @click:year="saveYear" ref="picker"
              :max="new Date().toISOString().replace(currentYear, getAvailableTaxYears.slice(0))"
              :min="new Date().toISOString().replace(currentYear, getAvailableTaxYears.slice(-1))" no-title scrollable>
            </v-date-picker>
          </v-menu>
        </v-row>
        <v-row no-gutters class="mb-3">
          <v-col>
            {{ $t('tax.taxCenterDescription') }}
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn @click="$router.push('/create-tax-report')" color="accent" class="white--text" tile
                :disabled="anyPersonalAddressUpdating || !anyTransactionPreviousYears || !anyPersonalAddress">
                {{ $t("tax.downloadTaxReportButton") }}
              </v-btn>
            </span>
          </template>
          <span v-if="anyPersonalAddressUpdating">{{ $t('message.taxReportAnyPersonalAddressUpdating') }}</span>
          <span v-else-if="!anyPersonalAddress">{{ $t('message.taxReportNoPersonalAddress') }}</span>
          <span v-else-if="!anyTransactionPreviousYears">{{ $t('message.taxReportNoTransactionPreviousYears') }}</span>
          <span v-else>{{ $t('message.createTaxReportTooltip') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <template v-if="$vuetify.breakpoint.xs">
      <v-row no-gutters :align="'center'" :justify="'center'">
        <v-col :align="'center'" :justify="'center'">
          <h1>{{ $t('tax.taxCenterTitle') }}</h1>
        </v-col>
      </v-row>
      <v-row no-gutters :align="'center'" :justify="'center'">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
          min-width="auto" offset-overflow left v-if="getAvailableTaxYears.length > 0">
          <template v-slot:activator="{ on, attrs }">
            <h1>{{ taxYear }}</h1><v-btn class="ml-3" v-bind="attrs" v-on="on" x-small fab
              color="accent"><v-icon>mdi-chevron-down</v-icon></v-btn>
          </template>
          <v-date-picker v-model="picker" @click:year="saveYear" ref="picker"
            :max="new Date().toISOString().replace(currentYear, getAvailableTaxYears.slice(0))"
            :min="new Date().toISOString().replace(currentYear, getAvailableTaxYears.slice(-1))" no-title scrollable>
          </v-date-picker>
        </v-menu>
      </v-row>
      <v-row no-gutters class="mb-3">
        <v-col>
          {{ $t('tax.taxCenterDescription') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col :align="'center'" :justify="'center'">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-btn @click="$router.push('/create-tax-report')" color="accent" class="white--text" tile
                  :disabled="anyPersonalAddressUpdating || !anyTransactionPreviousYears || !anyPersonalAddress">
                  {{ $t("tax.downloadTaxReportButton") }}
                </v-btn>
              </span>
            </template>
            <span v-if="anyPersonalAddressUpdating">{{ $t('message.taxReportAnyPersonalAddressUpdating') }}</span>
            <span v-else-if="!anyPersonalAddress">{{ $t('message.taxReportNoPersonalAddress') }}</span>
            <span v-else-if="!anyTransactionPreviousYears">{{ $t('message.taxReportNoTransactionPreviousYears')
              }}</span>
            <span v-else>{{ $t('message.createTaxReportTooltip') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-alert type="error" v-if="(hasCreatedTaxDocuments && taxDocuments.length != 1) && !areDocumentsUpdated">
          {{ $t('tax.taxReportNotUpdated') }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="!$vuetify.breakpoint.xs">
      <v-col cols="8">
        <v-row>
          <v-col>
            <h3>{{ $t('tax.taxReportTitle') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-alert type="success" v-if="hasCreatedTaxDocuments && taxDocuments.length != 1 && taxYearTransactions <= 50 && !loggedUser.pricingPlan.taxYearsAvailable.some(x => x.taxYear == taxYear)">
              {{ $t('tax.taxReportFreeAlert', {taxYear: taxYear, numTx: taxYearTransactions}) }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="hasCreatedTaxDocuments && taxDocuments.length != 1">
          <v-col v-for="(taxDocument, i) in taxDocuments" :key="i" cols="4">
            <v-card width="300" height="300" class="d-flex flex-column">
              <v-card-title>
                <v-row no-gutters :align="'center'" :justify="'center'">
                  <v-col cols="auto">
                    <h4>{{ taxDocument.taxDocumentType | TaxDocumentTypeFormatter }} - {{ taxYear }}</h4>
                  </v-col>

                </v-row>
              </v-card-title>
              <v-card-text>
                {{ taxDocument.taxDocumentType | TaxDocumentTypeDescriptionFormatter }}
              </v-card-text>
              <v-spacer />
              <v-card-actions v-if="taxDocument.fileId != null">
                <v-icon x-large :color="getColor(taxDocument)">{{ getIcon(taxDocument) }}</v-icon>
                <v-spacer />
                <div :align="'start'" :justify="'center'" v-if="isDownloadAvailable">
                  <template v-if="taxDocument.fileId != null">
                    <tax-report-download-button v-if="taxDocument.fileId != null"
                      :taxDocument="taxDocument"></tax-report-download-button>
                  </template>
                </div>
                <div v-else :align="'start'" :justify="'center'">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon @click="clickDownloadReport" v-on="on" color="info">
                        mdi-share
                      </v-icon>
                    </template>
                    <span>{{ getDownloadNotAvailableTooltip }}</span>
                  </v-tooltip>
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else-if="hasCreatedTaxDocuments && taxDocuments.length == 1">
          <v-col class="text-center align-center justify-center mt-15">
            <v-progress-circular indeterminate size="100" :width="10" color="accent"></v-progress-circular>
            <p class="mt-5">{{ $t('tax.circularProgressSentence') }}</p>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <v-alert type="info">{{ $t('tax.noComputedReports') }}</v-alert>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-row>
          <v-col>
            <h3>{{ $t('tax.taxSummaryTitle') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="pa-3">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h4>{{ $t('label.createdAt') }}</h4>
                  </v-col>
                  <v-col :align="'end'">
                    <h4>{{ hasCreatedTaxDocuments ? $options.filters.DateFormatter(getFirstDocument.created) : "-" }}
                    </h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4>{{ $t('label.totalConnections') }}</h4>
                  </v-col>
                  <v-col :align="'end'">
                    <h4>{{ hasCreatedTaxDocuments ? getFirstDocument.addresses.length : "-" }}</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4>{{ $t('label.totalTransactions') }}</h4>
                  </v-col>
                  <v-col :align="'end'">
                    <h4>{{ hasCreatedTaxDocuments ? taxYearTransactions : "-" }}</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4>{{ $t('label.costBasisMethod') }}</h4>
                  </v-col>
                  <v-col :align="'end'">
                    <h4>{{ hasCreatedTaxDocuments ?
      $options.filters.CostBasisMethodFormatter(getFirstDocument.costBasisMethodType) : "-" }}</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4>{{ $t('label.country') }}</h4>
                  </v-col>
                  <v-col :align="'end'">
                    <template v-if="hasCreatedTaxDocuments">
                      <v-avatar size="20">
                        <v-img :src="require(`../../assets/country/${getFirstDocument.countryType}.svg`)"></v-img>
                      </v-avatar>
                      <b class="mx-1">
                        {{ getFirstDocument.countryType | CountryTypeFormatter }}
                      </b>
                    </template>
                    <template v-else>
                      <b>-</b>
                    </template>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4>{{ $t('label.currency') }}</h4>
                  </v-col>
                  <v-col :align="'end'">
                    <template v-if="hasCreatedTaxDocuments">
                      <v-avatar size="20">
                        <v-img :src="require(`../../assets/currency/${getFirstDocument.currencyType}.svg`)"></v-img>
                      </v-avatar>
                      <b class="mx-1">
                        {{ getFirstDocument.currencyType }}
                      </b>
                    </template>
                    <template v-else>
                      <b>-</b>
                    </template>
                  </v-col>
                </v-row>
                <v-row :align="'center'">
                  <v-col cols="auto">
                    <h4>{{ $t('tax.emoney') }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <span>
                          {{ $t('tax.emoneyTooltip') }}
                        </span>
                      </v-tooltip>
                    </h4>
                  </v-col>
                  <v-spacer />
                  <v-col :align="'end'" cols="auto">
                    <h4>{{ hasCreatedTaxDocuments ?
      $options.filters.YesNoFormatter(getFirstDocument.emoney) : "-" }}</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4>{{ $t('tax.threshold') }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <span>
                          {{ $t('tax.thresholdTooltip') }}
                        </span>
                      </v-tooltip>
                    </h4>
                  </v-col>
                  <v-col :align="'end'">
                    <h4>{{ hasCreatedTaxDocuments ?
      $options.filters.YesNoFormatter(getFirstDocument.threshold) : "-" }}</h4>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-alert type="info" prominent>
              <v-row :align="'center'">
                <v-col>
                  {{ $t('tax.requestAccountantAlert') }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn @click="contactUs" outlined tile>{{ $t('plan.contactUs') }}</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.xs">
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t('tax.taxSummaryTitle') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="pa-3">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h4>{{ $t('label.createdAt') }}</h4>
                  </v-col>
                  <v-col :align="'end'">
                    <h4>{{ hasCreatedTaxDocuments ? $options.filters.DateFormatter(getFirstDocument.created) : "-" }}
                    </h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4>{{ $t('label.totalConnections') }}</h4>
                  </v-col>
                  <v-col :align="'end'">
                    <h4>{{ hasCreatedTaxDocuments ? getFirstDocument.addresses.length : "-" }}</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4>{{ $t('label.totalTransactions') }}</h4>
                  </v-col>
                  <v-col :align="'end'">
                    <h4>{{ hasCreatedTaxDocuments ? taxYearTransactions : "-" }}</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4>{{ $t('label.costBasisMethod') }}</h4>
                  </v-col>
                  <v-col :align="'end'">
                    <h4>{{ hasCreatedTaxDocuments ?
      $options.filters.CostBasisMethodFormatter(getFirstDocument.costBasisMethodType) : "-" }}</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4>{{ $t('label.country') }}</h4>
                  </v-col>
                  <v-col :align="'end'">
                    <template v-if="hasCreatedTaxDocuments">
                      <v-avatar size="20">
                        <v-img :src="require(`../../assets/country/${getFirstDocument.countryType}.svg`)"></v-img>
                      </v-avatar>
                      <b class="mx-1">
                        {{ getFirstDocument.countryType | CountryTypeFormatter }}
                      </b>
                    </template>
                    <template v-else>
                      <b>-</b>
                    </template>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4>{{ $t('label.currency') }}</h4>
                  </v-col>
                  <v-col :align="'end'">
                    <template v-if="hasCreatedTaxDocuments">
                      <v-avatar size="20">
                        <v-img :src="require(`../../assets/currency/${getFirstDocument.currencyType}.svg`)"></v-img>
                      </v-avatar>
                      <b class="mx-1">
                        {{ getFirstDocument.currencyType }}
                      </b>
                    </template>
                    <template v-else>
                      <b>-</b>
                    </template>
                  </v-col>
                </v-row>
                <v-row :align="'center'">
                  <v-col>
                    <h4>{{ $t('tax.emoney') }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <span>
                          {{ $t('tax.emoneyTooltip') }}
                        </span>
                      </v-tooltip>
                    </h4>
                  </v-col>
                  <v-spacer />
                  <v-col :align="'end'">
                    <h4>{{ hasCreatedTaxDocuments ?
      $options.filters.YesNoFormatter(getFirstDocument.emoney) : "-" }}</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4>{{ $t('tax.threshold') }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <span>
                          {{ $t('tax.thresholdTooltip') }}
                        </span>
                      </v-tooltip>
                    </h4>
                  </v-col>
                  <v-col :align="'end'">
                    <h4>{{ hasCreatedTaxDocuments ?
      $options.filters.YesNoFormatter(getFirstDocument.threshold) : "-" }}</h4>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.xs">
      <v-col>
        <v-alert type="info" prominent>
          <v-row :align="'center'">
            <v-col>
              {{ $t('tax.requestAccountantAlert') }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="contactUs" outlined tile>{{ $t('plan.contactUs') }}</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.xs">
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t('tax.taxReportTitle') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-alert type="success" v-if="hasCreatedTaxDocuments && taxDocuments.length != 1 && taxYearTransactions <= 50 && !loggedUser.pricingPlan.taxYearsAvailable.some(x => x.taxYear == taxYear)">
              {{ $t('tax.taxReportFreeAlert', {taxYear: taxYear, numTx: taxYearTransactions}) }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="hasCreatedTaxDocuments && taxDocuments.length != 1">
          <v-col v-for="(taxDocument, i) in taxDocuments" :key="i">
            <v-card width="300" height="300" class="d-flex flex-column">
              <v-card-title>
                <v-row no-gutters :align="'center'" :justify="'center'">
                  <v-col cols="auto">
                    <h4>{{ taxDocument.taxDocumentType | TaxDocumentTypeFormatter }} - {{ taxYear }}</h4>
                  </v-col>

                </v-row>
              </v-card-title>
              <v-card-text>
                {{ taxDocument.taxDocumentType | TaxDocumentTypeDescriptionFormatter }}
              </v-card-text>
              <v-spacer />
              <v-card-actions v-if="taxDocument.fileId != null">
                <v-icon x-large :color="getColor(taxDocument)">{{ getIcon(taxDocument) }}</v-icon>
                <v-spacer />
                <div :align="'start'" :justify="'center'" v-if="isDownloadAvailable">
                  <template v-if="taxDocument.fileId != null">
                    <tax-report-download-button v-if="taxDocument.fileId != null"
                      :taxDocument="taxDocument"></tax-report-download-button>
                  </template>
                </div>
                <div v-else :align="'start'" :justify="'center'">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon @click="clickDownloadReport" v-on="on" color="info">
                        mdi-share
                      </v-icon>
                    </template>
                    <span>{{ getDownloadNotAvailableTooltip }}</span>
                  </v-tooltip>
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else-if="hasCreatedTaxDocuments && taxDocuments.length == 1">
          <v-col class="text-center align-center justify-center mt-15">
            <v-progress-circular indeterminate size="100" :width="10" color="accent"></v-progress-circular>
            <p class="mt-5">{{ $t('tax.circularProgressSentence') }}</p>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <v-alert type="info">{{ $t('tax.noComputedReports') }}</v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <pro-feature-dialog :show="showProDialog" :fromButton="true" @closeDialog="showProDialog = false"
      :text="$t('plan.proPlanDialogDescriptionUserTaxReport')"></pro-feature-dialog>
  </v-container>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex';
import ProFeatureDialog from '../../components/dialog/ProFeatureDialog.vue';
import TaxReportDownloadButton from '../../components/button/TaxReportDownloadButton.vue';
export default {
  name: 'user-tax-center-page',
  components: {
    ProFeatureDialog,
    TaxReportDownloadButton
  },
  data() {
    return {
      dialog: false,
      refresh: false,
      showProDialog: false,
      menu: false,
      picker: null,
      currentYear: new Date().getFullYear(),
      taxYear: new Date().getFullYear() - 1
    }
  },
  computed: {
    ...mapFields("tax", ["availableTaxYears", "totalTransactions", "taxDocuments", "taxYearTransactions"]),
    ...mapFields("loggedUser", ["loggedUser"]),
    ...mapFields("userAddresses", ["addresses"]),
    getAvailableTaxYears(){
      // Get oldest year available and create a list of years until current year - 1
      let oldestYear = this.availableTaxYears.slice(-1)[0];
      let years = [];
      for (let i = oldestYear; i < this.currentYear; i++) {
        years.push(i);
      }
      // Reverse sort
      years.sort((a, b) => b - a);
      return years;
    },
    anyPersonalAddressUpdating() {
      return this.addresses.filter(x => x.userAddressType == 'PERSONAL').some(x => x.address.updating)
    },
    anyPersonalAddress() {
      return this.addresses.filter(x => x.userAddressType == 'PERSONAL').length > 0
    },
    anyTransactionPreviousYears() {
      return this.getAvailableTaxYears.length > 0
    },
    getFirstDocument() {
      return this.taxDocuments[0]
    },
    hasCreatedTaxDocuments() {
      return this.taxDocuments ? this.taxDocuments.length > 0 : false
    },
    areDocumentsUpdated() {
      for (let i = 0; i < this.addresses.length; i++) {
        let address = this.addresses[i].address;
        if (!this.getFirstDocument.addresses.includes(address.address)) {
          return false;
        } else {
          // Check if address update date is after the tax document creation date
          let addressUpdateDate = new Date(address.updated);
          let taxDocumentCreationDate = new Date(this.getFirstDocument.created);
          if (addressUpdateDate > taxDocumentCreationDate) {
            return false;
          } else {
            continue;
          }
        }
      }
      return true
    },
    isCurrentTaxYear() {
      return this.taxYear == new Date().getFullYear() - 1;
    },
    isDownloadAvailable() {
      if (this.loggedUser.userRole != 'USER') {
        return true;
      }
      if (this.taxYearTransactions <= 50) {
        return true;
      } else {
        return this.loggedUser.pricingPlan.taxYearsAvailable.some(x => x.taxYear == this.taxYear && x.transactionLimit >= this.taxYearTransactions)
      }
    },
    getDownloadNotAvailableTooltip() {
      let transactions = this.taxYearTransactions;
      let neededPlan = this.getNeededPlan(transactions);
      if (neededPlan == "ENTERPRISE") {
        return this.$t('message.contactUs')
      } else {
        if (this.loggedUser.externalTier != null){
          return this.$t('message.buyPlanYoungPlatform')
        }
        return this.$t('message.buyPlan', { plan: neededPlan, taxYear: this.taxYear })
      }
    },
    getSubject() {
      return this.$t('message.contactUsSubject')
    },
    getAccountantSubject() {
      return this.$t('message.contactUsAccountantSubject')
    }
  },
  methods: {
    ...mapActions("tax", ["fetchAvailableTaxYears", "fetchCurrentTaxYearTransactions", "fetchTaxDocuments", "askForAccountant", "fetchTaxYearTransactions"]),
    fetchData(success) {
      if (success) {
        this.refresh = true;
      }
    },
    contactUs() {
      this.askForAccountant();
      var mail = `mailto:support@okipo.io?subject=${this.getAccountantSubject}`;
      var a = document.createElement('a');
      a.href = mail;
      a.click();
    },
    saveYear(year) {
      this.taxYear = year
      this.menu = false
    },
    clickDownloadReport() {
      let transactions = this.taxYearTransactions;
      let neededPlan = this.getNeededPlan(transactions);
      if (neededPlan == "ENTERPRISE") {
        this.sendEmail()
      } else {
        this.$router.push({ name: 'Pricing Plans Page', params: { taxYear: this.taxYear } });
      }
    },
    getIcon(item) {
      switch (item.fileName.split('.')[1]) {
        case "pdf":
          return "mdi-file-pdf-box"
        case "xlsx":
          return "mdi-file-excel-box"
        default:
          return "mdi-file"
      }
    },
    getColor(item) {
      switch (item.fileName.split('.')[1]) {
        case "pdf":
          return "#F40F02"
        case "xlsx":
          return "#10793F"
        default:
          return "info"
      }
    },
    getNeededPlan(transactions) {
      if (transactions <= 1000) {
        return this.$options.filters.PricingPlanFormatter('BASE')
      } else if (transactions <= 3000) {
        return this.$options.filters.PricingPlanFormatter('STANDARD')
      } else if (transactions <= 10000) {
        return this.$options.filters.PricingPlanFormatter('PREMIUM')
      } else {
        return "ENTERPRISE"
      }
    },
    sendEmail() {
      var mail = `mailto:support@okipo.io?subject=${this.getSubject}`;
      var a = document.createElement('a');
      a.href = mail;
      a.click();
    }
  },
  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    taxYear(val, oldVal) {
      if (val !== oldVal) {
        this.fetchTaxDocuments(this.taxYear)
        this.fetchTaxYearTransactions(this.taxYear);
      }
    }
  },
  created() {
    this.fetchAvailableTaxYears();
    this.fetchCurrentTaxYearTransactions();
    this.fetchTaxDocuments(this.taxYear);
    this.fetchTaxYearTransactions(this.taxYear);
  },
}
</script>

<style></style>