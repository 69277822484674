<template>
    <v-container fluid>
        <v-tooltip top>
        <template v-slot:activator="{ on }">
            <div justify="center">
                <v-icon @click="showDialog ? confirmDialog = true : downloadFile()" v-on="on">mdi-cloud-download</v-icon><br>
            </div>
        </template>
        <span>{{ $t('message.downloadFile') }}</span>
        </v-tooltip>
        <confirm-dialog :title="$t('tax.confirmDownloadFileTitle')" :showDialog="confirmDialog" @confirmed="confirmDialog=false">
        <template v-slot:[`body`]>
            {{ $t('tax.confirmDownloadFileDescription') }}
            <!-- Add checkbox to confirm acceptation -->
            <v-checkbox color="accent" v-model="isButtonDisabled" :rules="[v => !!v || $t('tax.confirmDownloadFileCheckboxRequired')]" :label="$t('tax.confirmDownloadFileCheckbox')"></v-checkbox>
        </template>
        <template v-slot:[`confirm_button`]>
            <v-btn color="accent" class="white--text ma-3" @click="downloadFile()"
            :disabled="!isButtonDisabled">
            {{ $t("message.downloadFile") }}</v-btn>
        </template>
        </confirm-dialog>
    </v-container>
    
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ConfirmDialog from '../dialog/ConfirmDialog.vue';
import api from '@/store/utils/api';
import { mapFields } from 'vuex-map-fields';
export default {
    name:'tax-report-download-button',
    components: {
        ConfirmDialog
    },
    props: {
        taxDocument: {
            type: Object,
            required: true
        },
    },
    data(){
        return {
            confirmDialog: false,
            isButtonDisabled: false,
        }
    },
    computed: {
        ...mapFields("loggedUser", ["loggedUser"]),
        ...mapGetters("loggedUser", ["isWithdrawalPeriodForSubscription", "isWithdrawalPeriodForPreviousYears"]),
        isPreviousYear(){
            return this.taxDocument.taxYear < (new Date().getFullYear() - 1)
        },
        showDialog(){
            if (!this.isPreviousYear){
                if (this.loggedUser.userRole != 'USER') return false
                else if (this.taxDocument.totalTransactions <= 50) return false
                else if (this.isWithdrawalPeriodForSubscription) return true
                else return false
            } else {
                if (this.loggedUser.userRole != 'USER') return false
                if (this.taxDocument.totalTransactions <= 50) return false
                let taxYearAvailable = this.loggedUser.pricingPlan.taxYearsAvailable.filter(x => x.taxYear == this.taxDocument.taxYear)[0]
                if (!taxYearAvailable.hasDownloadedReport){
                    return true;
                } else {
                    return false;
                }
            }
        }
    },
    methods: {
        ...mapActions("alert", ["createAlert"]),
        ...mapActions("loggedUser", ["loadUserData"]),
        downloadFile(){
            api.get('/file/' + this.taxDocument.fileId, {responseType: 'blob'}).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.taxDocument.fileName);
                document.body.appendChild(link);
                link.click();
                this.confirmDialog = false;
                this.isButtonDisabled = false;
                this.loadUserData();
            }).catch((err) => {
                // Convert blob to text
                if (err.response.data && err.response.data.type == 'application/json') {
                    err.response.data.text().then((text) => {
                        let data = JSON.parse(text);
                        if (data.message == 'FILE_NOT_PRESENT') {
                            this.createAlert({ message: this.$t('message.fileNotPresentError'), type: "error" })
                        }
                        if (data.message == 'WRONG_PRICING_PLAN') {
                            this.createAlert({ message: this.$t('message.fileDownloadPricingError'), type: "error" })
                        }
                        if (data.message == 'USER_PLAN_NOT_ACTIVE'){
                            this.createAlert({ message: this.$t('message.fileDownloadUserPlanError'), type: "error" })
                        }
                    })
                } else {
                    this.createAlert({ message: this.$t('message.genericError'), type: "error" })
                }
            })
        }
    },
    
    mounted() {
        
    },
}
</script>

<style scoped>
    .file-size {
        white-space: nowrap;
    }
</style>